import React from 'react'
import './Programs.css'
import {programsData} from '../../data/programsData.js'
import RightArrow from '../../assets/rightArrow.png'
const Programs = () => {
  return (
    <div className='Programms' id='programs'>
        {/* header */}
        <div className='programs-header'>
            <span className='stroke-text'> Explore Our </span>
            <span> Programms </span>
            <span className='stroke-text'> To Shape You </span>
        </div>
        <div className='programs-categories'>
          {programsData.map((programs)=>(
            <div className='category'>
             {programs.image}
             <span> {programs.heading}</span>
             <span>{programs.details}</span>
             <div className='join-now'><span>Join Now</span> <img src={RightArrow}/></div>
            </div>
          ))}
        </div>
    </div>
  )
}

export default Programs