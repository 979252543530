import React, {useRef} from 'react'
import './Join.css'
import emailjs from '@emailjs/browser'
function Join() {
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_lfwpc7w', 'template_437heu8', form.current, 'Y4gBWHgfuLNW-VNqc')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
  return (
    <div className= 'Join' id='join-us'>
        <div className='Left-j'>
        <hr/>
            <div>
                <span className='stroke-text'> READY TO </span>
                <span> LEVEL UP </span>
            </div>
            <div>
                <span> YOUR BODY </span>
                <span  className='stroke-text'> WITH US? </span>
            </div>
        </div>
        <div className='Right-j'>
            <form ref={form} className='email-container' onSubmit={sendEmail}>
                <input type= 'email' name='user-email' placeholder='Enter your Email address'></input>
                <button className='btn btn-j'> Join Now </button>
            </form>
        </div>
    </div>
  )
}

export default Join